<template>
  <div>
    <el-row>
      <el-col v-for="(i, index) in list" :key="index" :span="4" :xs="8">
        <CardList :item="i"></CardList>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import CardList from '@/components/list/Card-List.vue';
import Expand from '@/request/expand.js';

export default {
  components: {
    CardList
  },
  data() {
    return {
      ask: new Expand(),
      param: {"type": "external_app"},
      list: [],
      ULHeight: window.innerHeight - 60 - 32 + 'px'
    }
  },
  computed: {
    noMore() {
      return this.count >= 200
    },
    disabled() {
      return this.loading || this.noMore
    }
  },
  mounted() {
    this.ask.getOtherData(this.param).then(res => {
        res.msg.forEach(item => {
          this.list.push(JSON.parse(item))
        })
    })
  },
  methods: {
    isAtBottom() {
      const ul = document.getElementById('listUl');
      return ul.scrollTop + ul.clientHeight >= ul.scrollHeight;
    },
    listScroll() {
      if (this.isAtBottom()) {
        // console.log('滚动到底部了！');
        for (let i = 0; i < 10; i++) {
          this.list.push(i)
        }
      }
    },
  }
}
</script>
<style>
ul{
  padding: 0;
  margin: 0;
  list-style: none;
}
</style>